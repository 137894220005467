import { create } from "zustand";

const useStore = create((set) => ({
  haveBet: sessionStorage.getItem("haveBet") === "true" || false,
  hasProcessed: sessionStorage.getItem("hasProcessed") === "true" || false,

  sethaveBet: (value) => {
    sessionStorage.setItem("haveBet", value.toString());
    set({ haveBet: value });
  },

  setHasProcessed: (value) => {
    sessionStorage.setItem("hasProcessed", value.toString());
    set({ hasProcessed: value });
  },
}));

export default useStore;
