import React from "react";

const Login = () => {
  return (
    <div>
      <h1>Login Page</h1>
    </div>
  );
};


export default Login;
