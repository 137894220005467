// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Aviator from "./screens/Aviator";
import SideNavbar from "./components/SideNavbar";
import Deposit from "./screens/Deposit";
import Withdraw from "./screens/Withdraw";
import Profile from "./screens/Profile";
import Login from "./screens/Login";
import Register from "./screens/Register";
import { API_BASE_URL } from "./constants";
import Alert from "./components/Alert";
import Swal from "sweetalert2";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [theme, setTheme] = useState("light");

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const toggleColorTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    console.log("Current theme:", newTheme);
    setTheme(newTheme);
  };

  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);

  const handleCloseRegister = () => setShowRegister(false);
  const handleShowRegister = () => setShowRegister(true);

  const [showError, setShowError] = useState(false);
  const [userData, setUserData] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [formData, setFormData] = useState({
    phoneNumber: "",
    password: "",
    confirm_password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    // Check if user is authenticated on component mount
    const checkAuth = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/auth/check`, {
          credentials: "include", // Include cookies in the request
        });
        const data = await response.json();
        setAuthenticated(data.authenticated);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    checkAuth();
  }, []);

  const handleRegisterSubmit = async (event) => {
    //get user details
    event.preventDefault();

    // Get input values from the form

    if (formData.confirm_password !== formData.password) {
      Swal.fire({
        icon: "error",
        title: "Registration Failed!",
        text: "Password Mismatched",
      });
    }

    //create user object
    const user = formData;

    //console.log(user);
    //send details to backend
    try {
      // Send the user details to the server using a POST request
      const response = await fetch(`${API_BASE_URL}/api/v1/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      // Check if the request was successful
      if (response.status === 200) {
        const data = await response.json();
        // Handle successful registration response from the server
        console.log("Registration successful:", data);
        handleCloseRegister();
        Swal.fire({
          icon: "success",
          title: "Registration Success",
          text: data.message,
        });
        // Optionally, you can redirect the user to a different page or display a success message
      } else {
        // Handle errors if the request was not successful
        const data = await response.json();
        console.log(data);

        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text: data.message,
        });

        // Optionally, display an error message to the user
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: error,
      });
      console.error("Error:", error);
      // Handle any unexpected errors
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/auth/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone, password }),
      });

      if (response.status === 200) {
        handleCloseLogin();
        // Login successful
        setAuthenticated(true);
        handleCloseLogin();

        Swal.fire({
          icon: "success",
          title: "LoggedIn!",
          text: "start playing",
        });
        const data = await response.json();
        console.log(data);

        // Redirect user to dashboard or other authenticated routes
      } else if (response.status === 201) {
        setAuthenticated(false);
        const data = await response.json();
        Swal.fire({
          icon: "error",
          title: "Login Failed!",
          text: data.message,
        });
      } else {
        // Login failed
        setAuthenticated(false);
        const data = await response.json();
        console.log(data);
        Swal.fire({
          icon: "error",
          title: "Login Failed!",
          text: data.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Login Failed!",
        text: "An error occurred while logging in",
      });
    }
  };
  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/auth/logout`, {
        method: "POST",
        credentials: "include", // Include cookies in the request
      });

      if (response.ok) {
        setAuthenticated(false);
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "Logout successful",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Function to fetch user data
    const fetchUserData = async () => {
      if (authenticated) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/v1/auth/user/data`,
            {
              method: "GET",
              credentials: "include", // Include cookies in the request
            }
          );

          if (response.status === 200) {
            const userData = await response.json();

            setUserData(userData); // Update userData state with fetched data
          } else if (response.status === 401) {
            console.log("Fetched...............", "denied");
          } else {
            console.log("Error fetching user data");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    // Call fetchUserData when the component mounts
    fetchUserData();
    //fetch in intervals
    // Update user balance at intervals of 5 seconds
    const intervalId = setInterval(fetchUserData, 1000);

    // Clean up function to clear the interval when the component unmounts or the dependency changes
    return () => clearInterval(intervalId);
  }, [authenticated]);

  const appClass = theme === "light" ? "main-app-light" : "main-app-dark";

  return (
    <Router>
      <div className={appClass}>
        {showError && (
          <Alert message="Error: Passwords do not match!" type="error" />
        )}
        <div className="row w-100 mb-0 pb-0">
          <div className="nav-menu-icon">
            <i
              className={`bi bi-list ${
                theme === "light" ? "text-dark" : "text-light"
              }`}
              onClick={toggleSidebar}
            ></i>

            <SideNavbar
              isOpen={sidebarOpen}
              authenticated={authenticated}
              handleShowLogin={handleShowLogin}
              toggleSidebar={toggleSidebar}
            />
          </div>
          {authenticated ? (
            <div className="col-12 d-flex justify-content-end gap-2 mt-3 pb-1 pt-0">
              <button
                className="btn btn-sm btn-warning auth-btn"
                onClick={handleLogout}
              >
                Logout <i className="bi bi-toggle-on"></i>
              </button>
              <button
                onClick={toggleColorTheme}
                className={`btn btn-sm auth-btn ${
                  theme === "light" ? "btn-light" : "btn-dark"
                }`}
              >
                <i
                  className={`bi ${theme === "light" ? "bi-moon" : "bi-sun"}`}
                ></i>
              </button>
            </div>
          ) : (
            <div className="col-12 d-flex justify-content-end gap-2 mt-3 pb-1 pt-0">
              <button
                onClick={handleShowLogin}
                className={`btn btn-outline-dark  ${
                  theme === "light"
                    ? "btn-light text-dark"
                    : "btn-dark text-light"
                } btn-sm text-success auth-btn px-2`}
              >
                Login
              </button>
              <button
                onClick={handleShowRegister}
                className="btn btn-sm btn-success auth-btn"
              >
                Register
              </button>

              <button
                onClick={toggleColorTheme}
                className={`btn btn-sm auth-btn ${
                  theme === "light" ? "btn-light" : "btn-dark"
                }`}
              >
                <i
                  className={`bi ${theme === "light" ? "bi-moon" : "bi-sun"}`}
                ></i>
              </button>
            </div>
          )}
        </div>

        <div
          className={`nav-content mt-0 mb-0 ${
            sidebarOpen ? "nav-sidebar-open" : ""
          }`}
        ></div>

        <Routes>
          <Route
            path="/"
            element={
              <Aviator
                userData={userData}
                toggleSidebarClose={toggleSidebarClose}
                authenticated={authenticated}
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/aviator"
            element={<Aviator toggleSidebar={toggleSidebar} />}
          />
          <Route
            path="/deposit"
            element={
              <Deposit userData={userData} authenticated={authenticated} />
            }
          />
          <Route
            path="/withdraw"
            element={
              <Withdraw userData={userData} authenticated={authenticated} />
            }
          />
          <Route
            path="/profile"
            element={
              <Profile userData={userData} authenticated={authenticated} />
            }
          />
          <Route path="/logout" element={<Login />} />
        </Routes>
        {/* add modals */}

        {showLogin && (
          <div className="modal-overlay card">
            <div className="modal mt-3 bg-secondary">
              <div className="modal-body">
                <div className="row ">
                  <div className="col-9">
                    <h4>Login</h4>
                  </div>
                  <div className="col float-end">
                    <button className="close-btn" onClick={handleCloseLogin}>
                      ×
                    </button>
                  </div>
                  <hr></hr>
                </div>
                <form onSubmit={handleLoginSubmit}>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Enter phone number"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="Password"
                    />
                  </div>
                  <button className="btn btn-info btn-sm w-100" type="submit">
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}

        {/* Register Modal */}
        {showRegister && (
          <div className="modal-overlay card">
            <div className="modal mt-3 bg-secondary">
              <div className="modal-body">
                <div className="row">
                  <div className="col-9">
                    <h4>Register</h4>
                  </div>
                  <div className="col float-end">
                    <button className="close-btn" onClick={handleCloseRegister}>
                      ×
                    </button>
                  </div>
                  <hr />
                </div>
                <form onSubmit={handleRegisterSubmit}>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Enter phone number"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      id="password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Password"
                    />
                  </div>
                  <div className="form-group">
                    <label>Repeat Password</label>
                    <input
                      id="confirm_password"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleInputChange}
                      type="password"
                      placeholder="Corfirm Password"
                    />
                  </div>
                  <button className="btn btn-info btn-sm w-100" type="submit">
                    Register
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
