// constants.js
//{base}/api/v1/payments/{service}

export const API_BASE_URL = "https://skymasta.com";
export const SOCKET_URL = "https://skymasta.com";
export const MAX_CRASH_POINT = 20;
export const MAX_CRASH_POINT_URL = "";
export const MAX_LOGIN_ATTEMPTS = 5;
export const COLORS = {
  PRIMARY: "#007bff",
  SECONDARY: "#6c757d",
  SUCCESS: "#28a745",
};
