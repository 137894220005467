import { create } from "zustand";

const useStore2 = create((set) => ({
  haveBet2: sessionStorage.getItem("haveBet2") === "true" || false,
  hasProcessed2: sessionStorage.getItem("hasProcessed2") === "true" || false,

  sethaveBet2: (value) => {
    sessionStorage.setItem("haveBet2", value.toString());
    set({ haveBet2: value });
  },

  setHasProcessed2: (value) => {
    sessionStorage.setItem("hasProcessed2", value.toString());
    set({ hasProcessed2: value });
  },
}));

export default useStore2;
