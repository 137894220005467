// Modal.js
import React from "react";
import "../styles/modal.css"; // Ensure you import the CSS

const Modal = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal w-100">
      <div className="modal-content">
        <h2>Connecting to server...</h2>

        <div className="powered-by">
          <p className="mb-0 pb-0 fs-6">powered by</p>
          <h4 className="text-info pt-0 mt-0 fw-bold">SKYSOFT</h4>
        </div>
        <div className="loading-dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
