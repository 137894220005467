import React from "react";

const Register = () => {
  return (
    <div>
      <h1>Register Page</h1>
    </div>
  );
};

export default Register;
