import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Alert,
  Button,
  CardText,
} from "react-bootstrap";
import {
  Phone,
  CurrencyDollar,
  FileText,
  People,
  Clipboard,
  PersonCircle,
  Wallet,
  CreditCard2Back,
  ClockHistory,
} from "react-bootstrap-icons";
import Game from "../Game";

const Dashboard = ({ userData, authenticated }) => {
  let Userphone = userData ? (userData.phone ? userData.phone : "07") : "07";
  let UserId = userData ? (userData.id ? userData.id : "5676") : "234";

  const [userDataFetched, setUserDataFetched] = useState(null);
  const [loading, setLoading] = useState(true);
  const referralLink = "https://skymasta.com"; ///user/data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://skymasta.com/api/v1/auth/user/profile`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer `,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUserDataFetched(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
        // Handle error state if needed
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const totalGames = userDataFetched ? userDataFetched.gamesCount : 0;
  const totalCashouts =
    userDataFetched && userDataFetched.cashouts
      ? userDataFetched.cashouts.amount
      : 0;
  const balance =
    userDataFetched && userDataFetched.details
      ? userDataFetched.details.balance
      : 0;

  const copyReferralLink = () => {
    // Your existing copy-to-clipboard logic
    alert("copied");
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <Spinner animation="border" role="status" className="text-primary fs-1">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <h2 className="mb-4">Your Profile Overview</h2>
      <Row className="gy-4">
        {/* User Information Card */}
        <Col xs={12} md={6}>
          <Card className="bg-light border-primary">
            <Card.Body>
              <Card.Title>User Information</Card.Title>
              <Card.Text>
                <PersonCircle className="me-2" size={24} />
                Phone: {Userphone}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Investment Portfolio Card */}
        <Col xs={12} md={6}>
          <Card className="bg-light border-success">
            <Card.Body>
              <Card.Title>Betting Portfolio</Card.Title>
              <Card.Text>
                <ClockHistory className="me-2" size={24} />
                Total Bets:{totalGames}
              </Card.Text>
              <Card.Text>
                <Wallet className="me-2" size={24} />
                Account Balance: KES.{balance}
              </Card.Text>
              <Card.Text>
                <CreditCard2Back className="me-2" size={24} />
                Total Cashouts: KES.{totalCashouts}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Links Card */}
        <Col xs={12} md={6}>
          <Card className="bg-light border-info">
            <Card.Body>
              <Card.Title>Quick Links</Card.Title>
              <Card.Text>
                <FileText className="me-2" size={24} />
                <Link to="/transactions" className="text-decoration-none">
                  View Transactions
                </Link>
              </Card.Text>
              <Card.Text>
                <People className="me-2" size={24} />
                <Link to="/teams" className="text-decoration-none">
                  View Teams
                </Link>
              </Card.Text>
              <hr></hr>
              <Card.Text className="text-center">
                Invite Friends To Earn Free Bets !
              </Card.Text>
              <CopyToClipboard
                text={`${referralLink}/register?ref=${UserId}`}
                onCopy={copyReferralLink}
              >
                <Card.Text className="d-flex align-items-center cursor-pointer">
                  <Clipboard className="me-2" size={24} />
                  <Button variant="link" className="p-0 text-decoration-none">
                    Copy My Referral Link
                  </Button>
                </Card.Text>
              </CopyToClipboard>
              <CardText>
                <input
                  readOnly={true}
                  type="text"
                  value={`${referralLink}/register?ref=${UserId}`}
                  className="form-control form-control-sm"
                ></input>
              </CardText>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
