import React, { useState } from "react";
import { API_BASE_URL } from "../constants";

const Withdraw = ({ userData, authenticated }) => {
  let Userphone = userData ? (userData.phone ? userData.phone : "07") : "07";
  var edit = true;
  if (Userphone === "07") {
    edit = false;
  }
  // State to store form data
  const [formData, setFormData] = useState({
    phone: Userphone,
    amount: "",
  });

  // Loading state for the STK push request
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${API_BASE_URL}/api/v1/payments/cashout`;
    const requestData = {
      amount: formData.amount,
      phone: formData.phone,
    };

    try {
      setLoading(true);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();
      setLoading(false);
      setResponse(responseData);
      if (response.ok) {
        console.log(" initiated successfully:", responseData);
      } else {
        console.error("Error initiating :", responseData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };
  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-12 col-lg-6 col-md-6">
          <div className="card w-100 max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <h5 className="card-title text-xl font-medium text-gray-900">
                WITHDRAW YOUR FUNDS
              </h5>
              <div className="form-group">
                <label htmlFor="phone" className="form-label">
                  Enter Phone
                </label>
                <input
                  readOnly={edit}
                  type="text"
                  name="phone"
                  id="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="07*********"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="amount" className="form-label">
                  Enter Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  placeholder="10000"
                  className="form-control"
                  required
                />
              </div>
              <button
                type="submit"
                className={`btn btn-primary w-100 ${loading ? "disabled" : ""}`}
                disabled={loading}
              >
                {loading ? "Sending request..." : "REQUEST PAYMENT"}
              </button>
            </form>
            {response && (
              <div
                className={`mt-4 rounded p-2 ${
                  response.success ? "bg-success" : "bg-warning text-dark"
                }`}
              >
                <p>{response.message}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
