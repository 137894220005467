import React from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";

import "../styles/SideNavbar.css";

const SideNavbar = ({
  isOpen,
  authenticated,
  handleShowLogin,
  toggleSidebar,
}) => {
  const navigate = useNavigate();

  const ensureLogin = (e) => {
    if (!authenticated) {
      e.preventDefault();
      navigate("/");
      handleShowLogin();
    }
  };

  return (
    <div className={`nav-sidebar bg-white ${isOpen ? "open" : ""}`}>
      <div className="navbar-brand bg-white" onClick={toggleSidebar}>
        <div className="row">
          <div className="col">SKYMASTA</div>
          <div className="col">
            <i className="bi bi-box-arrow-left text-dark"></i>
          </div>
        </div>
      </div>
      <div
        onClick={ensureLogin}
        className="navbar d-flex main-nav-bar flex-column vh-100"
      >
        <div className="navbar-collapse " onClick={toggleSidebar}>
          <div className="nav-main  m-0 p-0 flex-column text-dark">
            <Link className="nav-link  custom-link text-dark" to="/">
              <span className="nav-icon px-2">
                <i className="bi bi-house"></i>
              </span>
              Home
            </Link>

            <Link className="nav-link custom-link" to="/deposit">
              <span className="nav-icon px-2">
                <i className="bi bi-plus-circle"></i>
              </span>
              Deposit
            </Link>
            <Link className="nav-link custom-link" to="/withdraw">
              <span className="nav-icon px-2">
                <i className="bi bi-download"></i>
              </span>
              Withdraw
            </Link>

            <Link className="nav-link custom-link" to="/profile">
              <span className="nav-icon px-2">
                <i className="bi bi-person-bounding-box"></i>
              </span>
              Profile
            </Link>
            <Link className="nav-link custom-link" to="/logout">
              <span className="nav-icon px-2">
                <i className="bi bi-power"></i>
              </span>
              Logout
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
