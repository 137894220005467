import "../styles/betcard.css"; // Make sure to create and import the CSS file

const BetCard = ({
  cardType,
  activeTab,
  handleTabClick,
  startBet,
  betAmount,
  autoCashOut,
  handleCashOutChange,
  cashOutMultiplier,
  setCashOutMultiplier,
  handleBetAmountChange,
  handleBetAmountEdit,
  handleQuickAmountClick,
  disableStartButton,
}) => {
  const quickAmounts = [100, 200, 500, 10000];
  return (
    <div className="bet-card mt-1">
      <div className="bet-card-header">
        <button
          className={activeTab === "Manual" ? "active" : ""}
          onClick={() => handleTabClick("Manual")}
        >
          Bet
        </button>
        <button
          className={activeTab === "Auto" ? "active" : ""}
          onClick={() => handleTabClick("Auto")}
        >
          Auto
        </button>
      </div>
      <div className="bet-card-content">
        <div className="left-col ">
          {activeTab === "Manual" ? (
            <button
              id="create-bet-amt"
              onClick={startBet}
              disabled={disableStartButton}
              className="bet-button"
            >
              BET {Math.floor(betAmount)} KES
            </button>
          ) : (
            <button
              id="create-bet-amt"
              onClick={startBet}
              disabled={disableStartButton}
              className="bet-button"
            >
              BET {Math.floor(betAmount)} KES
            </button>
          )}
          {activeTab === "Auto" && (
            <div className="auto-cashout">
              <label className="auto-cashout-label">
                Auto Cash Out
                <input
                  type="checkbox"
                  checked={autoCashOut}
                  onChange={handleCashOutChange}
                />
              </label>
              {autoCashOut && (
                <input
                  type="number"
                  value={cashOutMultiplier}
                  onChange={(e) =>
                    setCashOutMultiplier(parseFloat(e.target.value))
                  }
                  step="0.1"
                />
              )}
            </div>
          )}
        </div>
        <div className="right-col">
          <div className="stake-amount">
            <button onClick={() => handleBetAmountChange(-10)}>-</button>
            <span
              contentEditable
              suppressContentEditableWarning
              onBlur={handleBetAmountEdit}
              className="editable-span"
              id="betamount_span"
            >
              {Math.floor(betAmount)}
            </span>
            <button onClick={() => handleBetAmountChange(10)}>+</button>
          </div>
          <div className="quick-amounts">
            {quickAmounts.map((amount) => (
              <button
                key={amount}
                onClick={() => handleQuickAmountClick(amount)}
              >
                {amount}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetCard;
