import React, { useState, useRef, useEffect } from "react";
import "../styles/header.css";
import socketManager from "../socketManager";

const GameHeader = ({
  userDataMain,
  isMenuVisible,
  toggleMenu,
  onToggle,
  canAnimate,
}) => {
  const socket = socketManager.getSocket();
  const audioRef = useRef(null);
  const startAudioRef = useRef(null);
  const crashedAudioRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(false);

  const toggleAudio = () => {
    if (isPlaying && isSoundOn) {
      audioRef.current.pause();
    } else if (!isPlaying && isSoundOn) {
      if (audioRef.current.readyState >= 2) {
        audioRef.current.play();
      } else {
        audioRef.current.addEventListener(
          "canplay",
          () => {
            audioRef.current.play();
          },
          { once: true }
        );
      }
    }
    setIsPlaying(!isPlaying);
  };

  const handleMusicToggle = () => {
    setIsSoundOn(!isSoundOn);
  };

  useEffect(() => {
    const handleSound = (type) => {
      if (isSoundOn) {
        switch (type) {
          case "start":
            startAudioRef.current.play();
            break;
          case "crashed":
            crashedAudioRef.current.play();
            break;
          default:
            break;
        }
      }
    };

    socket.on("restartGame", () => handleSound("start"));
    socket.on("gameCrashed", () => handleSound("crashed"));
  }, [isSoundOn, socket]);

  return (
    <div className="header">
      <div className="flex-container">
        <div className="group-left">
          <div className="col-auto mx-3">
            <em>
              <h4 className="aviator-logo">Aviator</h4>
            </em>
          </div>

          <div className="col">
            <i className="bi bi-info-circle custom-btn"></i>
          </div>
        </div>
        <div className="group-right">
          {userDataMain.authenticated && (
            <>
              {userDataMain ? (
                <div className="col balance-text mx-3">
                  {typeof userDataMain.userData.balance === "number"
                    ? userDataMain.userData.balance.toFixed(2)
                    : "N/A"}
                  <small className="currency">KES</small>
                </div>
              ) : (
                <div className="spinner-border text-success" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </>
          )}
          <div className="col mx-3 menu-icon" onClick={toggleMenu}>
            <i className="menu-icon bi bi-list"></i>
          </div>
        </div>
      </div>
      {isMenuVisible && (
        <div className="menu-popup card">
          <div className="card-body">
            <div className="user-info mb-3">
              <img src="/user.png" alt="User Avatar" className="user-avatar" />
              <span className="user-name">
                {userDataMain &&
                userDataMain.userData &&
                userDataMain.userData.phone
                  ? `${userDataMain.userData.phone.slice(
                      0,
                      4
                    )}**${userDataMain.userData.phone.slice(-1)}`
                  : "07***0"}
              </span>

              <button className="btn btn-link change-avatar">
                Change Avatar
              </button>
            </div>
            <div className="menu-options">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="soundToggle"
                  checked={isSoundOn}
                  onChange={handleMusicToggle}
                />
                <label className="form-check-label" htmlFor="soundToggle">
                  Sound
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="musicToggle"
                  checked={isPlaying && isSoundOn}
                  onChange={toggleAudio}
                  disabled={!isSoundOn}
                />
                <label className="form-check-label" htmlFor="musicToggle">
                  Music
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="animationToggle"
                  checked={canAnimate}
                  onClick={onToggle}
                />
                <label className="form-check-label" htmlFor="animationToggle">
                  Animation
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Background Audio */}
      <audio ref={audioRef} src="/audio/bg.mp3" loop />
      {/* Start Audio */}
      <audio ref={startAudioRef} src="/audio/start.aac" />
      {/* Crashed Audio */}
      <audio ref={crashedAudioRef} src="/audio/crashed.aac" />
    </div>
  );
};

export default GameHeader;
