import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import "../styles/CustomAlert.css";

const CustomAlert = ({
  handleClose,
  variant,
  header,
  message,
  autoDismiss,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (autoDismiss) {
      const timer = setTimeout(() => {
        setShow(false);
        handleClose(); // Call the handleClose function after auto-dismiss
      }, 5000); // Auto-dismiss after 5 seconds (5000 milliseconds)

      return () => clearTimeout(timer);
    }
  }, [autoDismiss, handleClose]);

  return (
    <div className="custom-alert">
      {show && (
        <Alert
          variant={variant}
          dismissible
          onClose={() => {
            setShow(false);
            handleClose();
          }}
        >
          {header && (
            <Alert.Heading className="custom-alert-header">
              {header}
            </Alert.Heading>
          )}
          {message && <p className="custom-alert-message">{message}</p>}
        </Alert>
      )}
    </div>
  );
};

export default CustomAlert;
