// socketManager.js
import io from "socket.io-client";
import { SOCKET_URL } from "./constants";

class SocketManager {
  constructor() {
    this.socket = null;
    this.connected = false; // Track connection status
    this.connect();
    this.disconnectCallback = () => {}; // Callback for handling disconnect
  }

  connect() {
    if (!this.socket || !this.connected) {
      this.socket = io(SOCKET_URL, {
        transports: ["websocket"], // Specify to use WebSocket transport
      });

      this.socket.on("connect", () => {
        console.log("connected to server ws");
        this.connected = true; // Update connection status
      });

      this.socket.on("disconnect", () => {
        console.log("disconnected from server ws");
        this.connected = false; // Update connection status
        // Retry connection every 5 seconds
        setTimeout(() => {
          this.connect();
        }, 5000);
      });
    }
  }

  getSocket() {
    return this.socket;
  }

  isConnected() {
    return this.connected;
  }

  setDisconnectCallback(callback) {
    this.disconnectCallback = callback;
  }
}

const socketManager = new SocketManager();
export default socketManager;
