import React, { useState, useEffect } from "react";
import "../styles/Alert.css"; // You can define styles for different types of alerts in this CSS file


function Alert({ message, type }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000); // Set timeout for 3 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`alert ${type} ${visible ? "show" : "hide"}`}>
      {message}
    </div>
  );
}

export default Alert;
